const roTests = {
  defaultDOB: '1990-01-01',
  boxes: [
    '136804',
    '136805',
    '136806',
    '136807',
    '136808',
    '136810',
    '136811',
    '136812',
    '136813',
    '136814',
    '136816',
    '136817',
    '136818',
    '136819',
    '136820',
    '136821',
    '136822',
    '136823',
    '136824',
    '136825',
    '136827',
    '136828',
    '136829',
    '136831',
    '136832',
    '136833',
    '136834',
    '136835',
    '136836',
    '136837',
    '136838',
    '136839',
    '136840',
    '136841',
    '136842',
    '136843',
    '136844',
    '136845',
    '136846',
    '136847',
    '136848',
    '136849',
    '136851',
    '136852',
    '136853',
    '136854',
    '136855',
    '136856',
    '136857',
    '136858',
    '136859',
    '136860',
    '136861',
    '136862',
    '136863',
    '136864',
    '136865',
    '136866',
    '136867',
    '136868',
  ],
}

export default roTests
