<template lang="pug">
#verify-box.step-component(:class="{ 'has-error': errors }")
  h2 First thing first, let's verify your kit box ID
  .input-container
    p(:class="{ 'has-error': $v.boxId.$error }") Box ID
    input.input(type="text", v-mask="'######'", v-model="boxId")
  .input-container
    p(:class="{ 'has-error': $v.birthdate.$error }") Your birthday (MM-DD-YYYY)
    .birthdate-wrapper
      input.input.birthdate(
        type="text",
        v-mask="'##-##-####'",
        v-model="birthdate",
        placeholder="MM - DD - YYYY"
      )

  VerificationButton.button(
    @click.native="submit()",
    text="Continue",
    :state="verificationButtonState"
  )

  p.is-error(v-if="errors") There was an error, please try again

  img#box-image(src="~@/assets/img/box-verification.jpg")
</template>

<script>
import { minLength, maxLength, required } from 'vuelidate/lib/validators'
import roTests from '@/data/ro-tests'

export default {
  props: {
    appendSteps: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      boxId: '',
      birthdate: null,
      hasError: false,
      verificationButtonState: 'init',
    }
  },
  validations: {
    boxId: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
    birthdate: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
  },
  computed: {
    errors,
    validationFailedEmailBody,
  },
  watch: {},
  methods: {
    formatDate,
    submit,
  },
  components: {
    ContactSupportBlock: require('@/components/ContactSupportBlock').default,
    VerificationButton: require('@/components/VerificationButton').default,
  },
}
/* Computed ---------------------------------------------------- */
function errors() {
  return this.$v.$anyError || this.hasError
}

function validationFailedEmailBody() {
  return `Attempting to validate kit '${this.boxId}' with birthdate '${this.birthdate}'`
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function formatDate(str) {
  var [month, day, year] = str.split('-')
  return `${year}-${month}-${day}`
}

async function submit() {
  this.verificationButtonState = 'try'
  this.$v.$touch()
  if (this.$v.$invalid) {
    this.verificationButtonState = 'init'
  } else {
    this.verificationButtonState = 'try'
    try {
      const barcode = this.boxId?.replace(/\D+/i, '')
      const response = await this.$store.dispatch('box/verifyFetch', {
        birthdate: roTests.boxes.includes(barcode)
          ? roTests.defaultDOB
          : this.formatDate(this.birthdate),
        barcode: barcode,
        amplitude_id: this.$route.query.q,
        isRedesign: true,
      })
      if (response.status == 200) {
        this.$bugsnag.setUser(response.data.barcodeId, null, null)
        this.$bugsnag.addMetadata('kitType', response.data.kitType)

        this.verificationButtonState = 'success'
        this.hasError = false
        this.$store.commit('user/SET_MEDPLUM_ORDER_ID', response.data.id)
        this.$store.commit('user/SET_BARCODE_ID', response.data.barcodeId)
        this.$store.commit('user/SET_KIT_TYPE', response.data.kitType)
        this.$store.commit('user/SET_FLOW_SECTIONS', response.data.flowSections)
        this.$store.commit('user/SET_CONFIG_KEYS', response.data.configKeys)
        this.$store.commit('user/SET_SKU', response.data.sku)
        this.$store.commit('user/SET_VIDEO_PERMISSION', true)
        localStorage.setItem('displayWarning', response.data.displayWarning)

        this.$amplitude.getInstance().setUserId(response.data.barcodeId)
        this.$mixpanel.identify(response.data.barcodeId)

        if (this.appendSteps) {
          this.$emit('append')
        }

        setTimeout(() => {
          let sections = this.$store.state.steps.sections
          let index = sections.findIndex(section => section.title == 'Day 2 Videos')
          if (response.data.dayOneSpecimenDate && index != -1) {
            this.$store.commit('steps/SET_CURRENT_SECTION', sections[index])
            this.$store.commit('steps/SET_CURRENT_STEP', sections[index].steps[0])
          } else {
            this.$emit('complete')
          }
        }, 1000)
      } else {
        this.verificationButtonState = 'init'
        this.hasError = true
      }
    } catch (error) {
      this.verificationButtonState = 'init'
      this.hasError = true
    }
  }
}
</script>
