<template lang="pug">
  #contact-support-block
    h3 {{ titleIntro }} Contact our support team for help:

    a.validation-help-button(:href="emailHref")
      icon(data="@icon/mail-outline.svg")
      span Email support
</template>

<script>
import { mapGetters } from 'vuex'
import Email from '@/utils/email'

export default {
  props: {
    titleIntro: {
      type: String,
      required: false,
      default: '',
    },
    emailSubject: {
      type: String,
      required: false,
      default: 'Help requested',
    },
    // Note: You can use \n to create a newline in the emailBody string
    emailBody: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      barcodeId: 'user/getBarcodeId',
    }),
    emailHref,
  },

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
function emailHref() {
  let email = new Email(this.emailSubject, this.emailBody, {
    'Box ID': this.barcodeId || 'not yet validated',
    URL: document.URL,
    UserAgent: navigator.userAgent,
  })
  return email.getHref()
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
