<template lang="pug">
  button.verification-button(:class="buttonClass")
    .vb-text(v-if="state == 'init'") {{ text }}
    .vb-svg(v-else)
      icon(v-if="state == 'try'" data='~@icon/spinner.svg' height="32" width="32" original :fill="false")
      icon(v-else data='~@icon/check.svg' height="32" width="32" original :fill="false")
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String,
    },
    state: {
      required: true,
      type: String,
      validator: function(value) {
        return ['init', 'try', 'success'].indexOf(value) !== -1
      },
    },
  },

  data() {
    return {}
  },

  computed: {
    buttonClass,
  },

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
function buttonClass() {
  return `vb-${this.state}`
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
